exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acerca-de-js": () => import("./../../../src/pages/acerca-de.js" /* webpackChunkName: "component---src-pages-acerca-de-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-eventos-js": () => import("./../../../src/pages/eventos.js" /* webpackChunkName: "component---src-pages-eventos-js" */),
  "component---src-pages-exposiciones-alex-escobedo-js": () => import("./../../../src/pages/exposiciones/Alex-Escobedo.js" /* webpackChunkName: "component---src-pages-exposiciones-alex-escobedo-js" */),
  "component---src-pages-exposiciones-clavo-js": () => import("./../../../src/pages/exposiciones/clavo.js" /* webpackChunkName: "component---src-pages-exposiciones-clavo-js" */),
  "component---src-pages-exposiciones-dalzell-roman-js": () => import("./../../../src/pages/exposiciones/Dalzell-Roman.js" /* webpackChunkName: "component---src-pages-exposiciones-dalzell-roman-js" */),
  "component---src-pages-exposiciones-daniloween-gallery-take-over-js": () => import("./../../../src/pages/exposiciones/Daniloween-Gallery-Take-Over.js" /* webpackChunkName: "component---src-pages-exposiciones-daniloween-gallery-take-over-js" */),
  "component---src-pages-exposiciones-edc-js": () => import("./../../../src/pages/exposiciones/EDC.js" /* webpackChunkName: "component---src-pages-exposiciones-edc-js" */),
  "component---src-pages-exposiciones-el-sonidito-1-js": () => import("./../../../src/pages/exposiciones/El-Sonidito-1.js" /* webpackChunkName: "component---src-pages-exposiciones-el-sonidito-1-js" */),
  "component---src-pages-exposiciones-escribir-con-tierra-js": () => import("./../../../src/pages/exposiciones/Escribir-Con-Tierra.js" /* webpackChunkName: "component---src-pages-exposiciones-escribir-con-tierra-js" */),
  "component---src-pages-exposiciones-fabiana-mapel-js": () => import("./../../../src/pages/exposiciones/Fabiana-Mapel.js" /* webpackChunkName: "component---src-pages-exposiciones-fabiana-mapel-js" */),
  "component---src-pages-exposiciones-gringofobicx-js": () => import("./../../../src/pages/exposiciones/Gringofobicx.js" /* webpackChunkName: "component---src-pages-exposiciones-gringofobicx-js" */),
  "component---src-pages-exposiciones-index-js": () => import("./../../../src/pages/exposiciones/index.js" /* webpackChunkName: "component---src-pages-exposiciones-index-js" */),
  "component---src-pages-exposiciones-janet-40-js": () => import("./../../../src/pages/exposiciones/Janet40.js" /* webpackChunkName: "component---src-pages-exposiciones-janet-40-js" */),
  "component---src-pages-exposiciones-mini-bar-el-renacuajo-js": () => import("./../../../src/pages/exposiciones/mini-bar-el-renacuajo.js" /* webpackChunkName: "component---src-pages-exposiciones-mini-bar-el-renacuajo-js" */),
  "component---src-pages-exposiciones-museo-jumex-2-js": () => import("./../../../src/pages/exposiciones/museo-jumex-2.js" /* webpackChunkName: "component---src-pages-exposiciones-museo-jumex-2-js" */),
  "component---src-pages-exposiciones-pake-taxo-js": () => import("./../../../src/pages/exposiciones/PAKE-TAXO.js" /* webpackChunkName: "component---src-pages-exposiciones-pake-taxo-js" */),
  "component---src-pages-exposiciones-petroglyph-park-js": () => import("./../../../src/pages/exposiciones/Petroglyph-Park.js" /* webpackChunkName: "component---src-pages-exposiciones-petroglyph-park-js" */),
  "component---src-pages-exposiciones-proyecto-eme-js": () => import("./../../../src/pages/exposiciones/Proyecto-EME.js" /* webpackChunkName: "component---src-pages-exposiciones-proyecto-eme-js" */),
  "component---src-pages-exposiciones-puro-show-js": () => import("./../../../src/pages/exposiciones/puro-show.js" /* webpackChunkName: "component---src-pages-exposiciones-puro-show-js" */),
  "component---src-pages-exposiciones-radio-rpg-js": () => import("./../../../src/pages/exposiciones/radio-rpg.js" /* webpackChunkName: "component---src-pages-exposiciones-radio-rpg-js" */),
  "component---src-pages-exposiciones-santiago-gomez-js": () => import("./../../../src/pages/exposiciones/Santiago-Gomez.js" /* webpackChunkName: "component---src-pages-exposiciones-santiago-gomez-js" */),
  "component---src-pages-exposiciones-sucesiva-x-gracias-js": () => import("./../../../src/pages/exposiciones/SUCESIVA-x-GRACIAS.js" /* webpackChunkName: "component---src-pages-exposiciones-sucesiva-x-gracias-js" */),
  "component---src-pages-exposiciones-un-burro-muerto-js": () => import("./../../../src/pages/exposiciones/Un-burro-muerto.js" /* webpackChunkName: "component---src-pages-exposiciones-un-burro-muerto-js" */),
  "component---src-pages-exposiciones-videoke-corner-js": () => import("./../../../src/pages/exposiciones/videoke-corner.js" /* webpackChunkName: "component---src-pages-exposiciones-videoke-corner-js" */),
  "component---src-pages-exposiciones-violeta-delfin-js": () => import("./../../../src/pages/exposiciones/Violeta-Delfin.js" /* webpackChunkName: "component---src-pages-exposiciones-violeta-delfin-js" */),
  "component---src-pages-hidden-js": () => import("./../../../src/pages/hidden.js" /* webpackChunkName: "component---src-pages-hidden-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

